import React from 'react';
import Layout from '../../layouts/index';
import LifestyleExaminationImg from '../../images/lifestyle-examination.jpg'
import HealthScreeningNormalImg from '../../images/health-screening-normal.jpg'
import HealthScreeningMoreImg from '../../images/health-screening-more.jpg'
import HealthScreeningCompanyImg from '../../images/health-screening-company.jpg'

const AboutCourseContent = () => (
  <div className="container sub-content">
    <h1>どこまで診断すればよいか</h1>
    <div>
      <p>
        健診を受ける際、どこまで検査を受ければいいのか悩むことも少なくありません。
        <br />
        当院では、それぞれのニーズと予算に合わせて利用できるよう、わかりやすいメニューを心がけております。
      </p>
      <p>
        この度、あなたにピッタリな人間ドックを選べるサイトができました。
        <br />
        <a href="https://choice.hirahata.net/" target="_blank">
          https://choice.hirahata.net/
        </a>
        <br />
        簡単な質問に答えていただくだけで、最適なコースとオプションを提案させていただきます。また、そのままお申し込みも可能です。
      </p>
      <p>
        すべてのコースで、何か異常があれば保険診療に移行してさらに精密な検査を行うことができます。
        <br />
        また、気になるところだけオプションで検査を追加することもできます。
        <br />
        すべての女性にお勧めなのが、乳腺エコーです。ベテランの女性技師が、乳がんのリスクをしっかりチェックします。
      </p>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={LifestyleExaminationImg}
          />
        </div>
        <div className="col-sm-8">
          <h3>生活習慣病健診</h3>
          <h4>身体の状態を一通り調べる際に最適</h4>
          <p>
            費用は抑えたいが、大事なところはしっかりみておきたい、という方に。
            <br />
            20～30代前半の方はこちらに気になる項目を追加されることが多いようです。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={HealthScreeningNormalImg}
          />
        </div>
        <div className="col-sm-8">
          <h3>人間ドック標準コース</h3>
          <h4>詳細に身体の状態を調べたい方にお勧め</h4>
          <p>
            生活習慣病健診より、血液検査の内容もさらに充実。
            <br />
            30代後半からはこちらが安心。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={HealthScreeningMoreImg}
          />
        </div>
        <div className="col-sm-8">
          <h3>人間ドック精密コース</h3>
          <h4>がんのリスクが気になる方お勧め</h4>
          <p>
            標準コースに、腫瘍マーカー、乳腺エコーをプラス。
            <br />
            がん年齢に近づいた40代以降の方は、こちらがお勧めです。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={HealthScreeningCompanyImg}
          />
        </div>
        <div className="col-sm-8">
          <h3>一般定期健診（＋オプション）</h3>
          <p>
            一般的な企業健診（法定健診）になります。
            <br />
            採血があるコースの場合、貧血、肝機能、血中脂質（コレステロール、中性脂肪など）、血糖値などが調べられます。
            <br />
            ＋5,400円で、血液検査だけドックの項目にすることも可能。
            <br />
            食道・胃・十二指腸が気になる方は、鼻からの胃カメラ（内視鏡）を追加するのがお勧めです。
            <br />
            婦人科系などが気になる方は、これに女性技師による乳腺エコーなどを追加していただくのがお勧めです。
          </p>
        </div>
      </div>
    </div>
  </div>
);

const AboutCourse = () => (
  <Layout>
    <AboutCourseContent />
  </Layout>
);

export default AboutCourse;
